
            
            import { runInjectionScript } from "astro-clerk-auth/internal";
            await runInjectionScript({"afterSignInUrl":"/","afterSignUpUrl":"/"});
import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://8777e015a30ab4f9cc966ba6677eba1b@o387780.ingest.us.sentry.io/4507493222187008",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});